@import "../tracker/TrackerSteps";
$color-concrete-gray: #C9C7BA;

.step-last-icon {
    @extend %step-icon;
    width: 10px;
    height: 10px;
    margin: 3px;
    background-color: $color-concrete-gray;
}

.order-progress-bar::after {
    width: 2px;
    min-height: 45px;
    content: '';
    position: relative;
    display: inline-block;
    margin-left: 7px;
    border-radius: 99px;
    margin-top: 2px;
    margin-bottom: 2px;
}

@for $i from 0 through 100 {
    .progress-#{$i}::after {
        background: linear-gradient(to bottom, $color-green-400 0, $color-green-400 percentage(calc($i / 100)), $color-concrete-gray percentage(calc($i / 100)), $color-concrete-gray percentage(calc((100 - $i) / 100)));
    }
}
 