table {
    border-collapse: collapse;
}

td {
    vertical-align: top;
}

.table-row-divider {
    border-bottom: 1px lightgrey solid;
}

