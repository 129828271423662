$color-green-400: #067D62;
$color-pending-grey: #C7C7C7;
%step-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-position: 50% 50%;
}

%step-vertical-line {
  width: 2px;
  height: 15px;
  margin-right: 14.25px;
  margin-bottom: 3.7px;
  margin-left: 7px;
  border-radius: 99px;
}

.step-completed-icon {
  @extend %step-icon;
  border: 2px solid $color-green-400;
  background-color: $color-green-400;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTEnIGhlaWdodD0nOCcgdmlld0JveD0nMCAwIDExIDgnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTMuNTAxNjQgOEwwLjc5Mzg1MSA1LjQzMjg4QzAuNjA1NzAyIDUuMjUzODQgMC41IDUuMDExMDIgMC41IDQuNzU3ODJDMC41IDQuNTA0NjIgMC42MDU3MDEgNC4yNjE4IDAuNzkzODUxIDQuMDgyNzZDMC45ODIwMDEgMy45MDM3MyAxLjIzNzE5IDMuODAzMTQgMS41MDMyNyAzLjgwMzE0QzEuNzY5MzUgMy44MDMxNCAyLjAyNDU0IDMuOTAzNzMgMi4yMTI2OSA0LjA4Mjc2TDMuNTAxNjQgNS4zMTg3OEw4Ljc4NzMxIDAuMjc5NjE4QzguOTc1NDYgMC4xMDA1ODIgOS4yMzA2NSAwIDkuNDk2NzMgMEM5Ljc2MjgxIDAgMTAuMDE4IDAuMTAwNTgyIDEwLjIwNjIgMC4yNzk2MThDMTAuMzk0MyAwLjQ1ODY1NSAxMC41IDAuNzAxNDggMTAuNSAwLjk1NDY3NkMxMC41IDEuMjA3ODcgMTAuMzk0MyAxLjQ1MDcgMTAuMjA2MiAxLjYyOTczTDMuNTAxNjQgOFonIGZpbGw9J3doaXRlJy8+PC9zdmc+");
  background-repeat: no-repeat;
}

.step-pending-icon {
  @extend %step-icon;
  border: 2px solid $color-pending-grey;
  background-color: white;
}

.step-completed-vertical-line {
  @extend %step-vertical-line;
  background-color: $color-green-400;
}

.step-pending-vertical-line {
  @extend %step-vertical-line;
  background-color: $color-pending-grey;
}